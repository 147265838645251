// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")

window.onload=function() {

  let importButton = document.querySelector('input#import_user_products');
  let noticeCloseButton = document.querySelector('.Polaris-Banner--statusInfo .Polaris-Banner__Dismiss button');
  let alertCloseButton = document.querySelector('.Polaris-Banner--statusWarning .Polaris-Banner__Dismiss button');

  // if (importButton.dataset.importRunning == 'true') {
  //   importButton.disabled = true;
  //   document.querySelector('span#import-state').innerHTML = 'Import is running';
  // }

  if (noticeCloseButton) {
    noticeCloseButton.addEventListener('click', function () {
      this.parentNode.parentNode.style.display = "none";
    });
  }

  if (alertCloseButton) {
    alertCloseButton.addEventListener('click', function () {
      this.parentNode.parentNode.style.display = "none";
    });
  }
  
  if (importButton) {
    importButton.addEventListener('click', function () {
      document.querySelector('span#import-state').innerHTML = 'Import is running';
      setTimeout(function() {
        importButton.disabled = true;
      }, 1);
    });
  }
}

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
